import React from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardTitle,
    MDBCol,
    MDBRow
} from "mdb-react-ui-kit";
import medimusic from "../library/apiClient/medimusic";

function Playlists({authentication, isAuth, currentPlaylist, setCurrentPlaylist}) {

    const [playlistCards, setPlaylistCards] = React.useState(null);
    React.useEffect(() => {

        if (!isAuth) {
            return;
        }

        const playlistResponse = (response) => {
            let pl_cards = [];

            response.data.playlists.forEach((playlistItem) => {

                if (0 === playlistItem.trackCount) {
                    return;
                }

                pl_cards.push(
                    <MDBCol md='4' lg='3' xl='3' xxl='2' className={'playlist-card mb-2 mt-2 ' + (playlistItem.slug === currentPlaylist?.slug ? 'active' : '')} key={playlistItem.slug}>
                        <MDBCard
                            onClick={() => {playlistItem.slug === currentPlaylist?.slug ? setCurrentPlaylist(null) : setCurrentPlaylist(playlistItem)}}
                        >
                            <MDBCardBody
                                className={'rounded-5'}
                                style={{backgroundImage: `url(${playlistItem.artwork})`}}
                            >
                            </MDBCardBody>
                            <MDBCardFooter>
                                <MDBCardTitle tag='h3'>{playlistItem.name}</MDBCardTitle>
                            </MDBCardFooter>
                        </MDBCard>
                    </MDBCol>
                );
            });

            setPlaylistCards(pl_cards);
        };

        medimusic.getPlaylists(
            window.localStorage.getItem('access_token'),
            playlistResponse,
            (error) => {
                if (401 === error?.response?.status) {
                    const refreshToken = window.localStorage.getItem('refresh_token')

                    if (null === refreshToken) {
                        return;
                    }

                    authentication.refreshAccessToken(refreshToken, () => {
                        medimusic.getPlaylists(
                            window.localStorage.getItem('access_token'),
                            playlistResponse,
                            (error) => {

                            }
                        );
                    });
                }
            }
        );

    }, [isAuth, currentPlaylist]);

    return (
        <MDBCol md='12' className={'px-5 playlist-container'}>
            <MDBRow>
                <MDBCol className={'playlist-heading-container col-12 col-xl-5 py-sm-5'}>
                    <h2 className='playlist-heading'>Preconfigured Playlists</h2>
                    <p className={'playlist-sub-heading'}>A subheading with a brief description of you, your work, and
                        what you’re all about—no biggie</p>
                </MDBCol>
                <div className='w-100'></div>
                {playlistCards}
            </MDBRow>
        </MDBCol>
    );
}

export default Playlists;