import {MDBCol, MDBRow, MDBTypography} from "mdb-react-ui-kit";
import React from "react";

const Questions = [
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Feeling nervous, anxious or on edge?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    },
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Not being able to stop or control worrying?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    },
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Worrying too much about different things?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    },
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Trouble relaxing?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    },
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Being so restless that it is hard to sit still?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    },
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Becoming easily annoyed or irritable?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    },
    {
        subheading: 'Over the last two weeks, how often have you been bothered by any of the following problems?',
        question: 'Feeling afraid as if something awful might happen?',
        answers: [
            {type: 'radio', text: 'Not at all'},
            {type: 'radio', text: 'Several days'},
            {type: 'radio', text: 'More than half the days'},
            {type: 'radio', text: 'Nearly every day'},
        ]
    }
];

const Question = ({questionIndex}) => {
    let cards = [];
    Questions.forEach((question, index) => {

        let answers = [];
        question.answers.forEach((_answer, index) => {
            answers.push(
                <MDBCol size='12'>
                    <label><input type={_answer.type}/>{_answer.text}</label>
                </MDBCol>
            );
        });
        cards.push(
            <div className={questionIndex === index ? '' : 'hidden'}>
                <MDBTypography variant='h5' className='survey-subheading'>{question.subheading}</MDBTypography>
                <hr className="hr"/>
                <MDBRow>
                    <MDBCol size='8'>
                        {question.question}
                    </MDBCol>
                    <MDBCol size='4'>
                        <MDBRow>
                            {answers}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    });

    return (
        <>
            {cards}
        </>
    );
};

export default Question;
export {Question, Questions};