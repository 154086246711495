import {
    MDBContainer,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBNavbar,
    MDBNavbarBrand
} from "mdb-react-ui-kit";
import logo from "../images/MediMusic_Brand_Logo_RGB_Horizontal_Colour.png";
import avatar from "../images/avatar.svg";
import React from "react";

function Navbar({isAuth, userInfo, displayUserSettings}) {

    return (
        <MDBNavbar expand='lg' light className={'px-4 main-navigation'}>
            <MDBContainer fluid>
                <MDBNavbarBrand className='d-flex align-items-center'>
                    <img src={logo} className="App-logo" alt="logo" height={88}/>
                </MDBNavbarBrand>

                <div className='d-flex align-items-center'>
                    { isAuth &&
                        <MDBDropdown>
                            <MDBDropdownToggle style={{ cursor: 'pointer' }} tag='a' className='text-reset me-3 hidden-arrow user-profile-image'>
                                <img
                                    src={avatar}
                                    alt='Black and White Portrait of a Man'
                                    loading='lazy'
                                />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                                <MDBDropdownItem link>{userInfo.name} {userInfo.family_name}</MDBDropdownItem>
                                <MDBDropdownItem onClick={displayUserSettings} link>Settings</MDBDropdownItem>
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    }
                </div>
            </MDBContainer>
        </MDBNavbar>
    );
}

export default Navbar;