import React from "react";
import {MDBCol, MDBRow, MDBContainer} from "mdb-react-ui-kit";
import Audio from "./Audio";
import medimusic from "../library/apiClient/medimusic";

function Player({authentication, currentPlaylist, setCurrentPlaylist, setDisplaySurveyModal, prePlaylistSurveyCompleted, setPrePlaylistSurveyCompleted}) {

    const [tracks, setTracks] = React.useState(null);
    const [currentTrack, setCurrentTrack] = React.useState(null);
    const [currentTrackDownload, setCurrentTrackDownload] = React.useState(null);
    const [currentTrackIndex, setCurrentTrackIndex] = React.useState(0);

    React.useEffect(() => {

        if (tracks) {
            setCurrentTrackIndex(0)
            setCurrentTrack(tracks[0] || null);
        }

    }, [tracks]);

    React.useEffect(() => {
        if (currentPlaylist) {
            setDisplaySurveyModal(true);

            medimusic.getPlaylist(
                window.localStorage.getItem('access_token'),
                currentPlaylist.slug,
                (response) => {
                    setTracks(response.data.tracks);
                },
                (error) => {
                    if (401 === error?.response?.status) {
                        const refreshToken = window.localStorage.getItem('refresh_token')

                        if (null === refreshToken) {
                            return;
                        }

                        authentication.refreshAccessToken(refreshToken, () => {
                            medimusic.getPlaylist(
                                window.localStorage.getItem('access_token'),
                                currentPlaylist.slug,
                                (response) => {
                                    setTracks(response.data.tracks);
                                },
                                (error) => {

                                }
                            );
                        });
                    }
                }
            )
        }
    }, [currentPlaylist]);


    React.useEffect(() => {

        if (tracks) {
            setCurrentTrack(tracks[currentTrackIndex] || null);
        }

    }, [currentTrackIndex]);

    React.useEffect(() => {

        if (!currentTrack || !currentTrack.tg_id) {
            return;
        }

        medimusic.downloadTrack(
            window.localStorage.getItem('access_token'),
            currentTrack?.tg_id,
            (response) => {
                if (currentTrackDownload !== response.data) {
                    setCurrentTrackDownload(response.data);
                }
            },
            (error) => {
                if (401 === error?.response?.status) {
                    const refreshToken = window.localStorage.getItem('refresh_token')

                    if (null === refreshToken) {
                        return;
                    }
                    authentication.refreshAccessToken(refreshToken, () => {
                        medimusic.downloadTrack(
                            window.localStorage.getItem('access_token'),
                            currentTrack?.tg_id,
                            (response) => {
                                if (currentTrackDownload !== response.data) {
                                    setCurrentTrackDownload(response.data);
                                }
                            },
                            (error) => {}
                        );
                    });
                }
            }
        )
    }, [currentTrack]);

    return (
        <>
            {currentPlaylist && <MDBContainer fluid className={'player-container'}>
                <MDBRow>
                    <MDBCol sm={12} md={5} lg={4} xxl={3} className='track-artwork-container placeholder-glow'>
                        {prePlaylistSurveyCompleted && currentTrack ? <img src={currentTrack?.trackImageUrl} alt={''}/> : <span className='placeholder image-placeholder col-6'></span> }
                    </MDBCol>
                    <MDBCol sm={12} md={7} lg={8} xxl={9} className='current-track-details'>
                        <h3 className={'placeholder-glow'}>{prePlaylistSurveyCompleted && currentTrack ? currentPlaylist.name : <span className='placeholder col-4'></span>}</h3>
                        <p className={'placeholder-glow'}>{prePlaylistSurveyCompleted && currentTrack ? 'Track' : <span className='placeholder col-1'></span> }</p>
                        <p className={'placeholder-glow track-title'}>
                            {prePlaylistSurveyCompleted && currentTrack ? currentTrack.name : <span className='placeholder col-6'></span> }
                        </p>
                        <p className={'placeholder-glow artist-name'}>
                            {prePlaylistSurveyCompleted && currentTrack ? currentTrack.artist : <span className='placeholder col-5'></span> }
                        </p>
                    </MDBCol>
                    <>
                    {currentTrackDownload && prePlaylistSurveyCompleted && <Audio
                        src={currentTrackDownload}
                        currentTrack={currentTrack}
                        setCurrentTrack={setCurrentTrack}
                        tracks={tracks}
                        currentTrackIndex={currentTrackIndex}
                        setCurrentTrackIndex={setCurrentTrackIndex}
                        currentPlaylist={currentPlaylist}
                    />
                    }
                    </>
                </MDBRow>
            </MDBContainer>}
        </>
    );
}

export default Player;