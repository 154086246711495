
import axios from "axios";
import config from '../../config';

class medimusic {
    static getAccessToken(code, successResponse, errorResponse) {
        axios.get(
            `${config.API_URL}oauth2/token?code=${code}&redirect_uri=${config.HOST_URL}`,
            {
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'x-client-id': `${config.COGNITO_CLIENT_ID}`,
                }
            }
        ).then(successResponse).catch(errorResponse);
    }
    static refreshAccessToken(refreshToken, successResponse, errorResponse) {
        axios.postForm(`${config.API_URL}oauth2/token`, {
            'refresh_token': refreshToken
        },{
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'x-client-id': `${config.COGNITO_CLIENT_ID}`,
            }
        }).then(successResponse).catch(errorResponse);
    }

    static getPlaylists(accessToken, successResponse, errorResponse) {
        axios.get(`${config.API_URL}api/playlists`, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${accessToken}`,
                'x-client-id': `${config.COGNITO_CLIENT_ID}`,
            }
        }).then(successResponse).catch(errorResponse);
    }

    static getPlaylist(accessToken, playlistId, successResponse, errorResponse) {
        axios.get(`${config.API_URL}api/playlists/${playlistId}`, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${accessToken}`,
                'x-client-id': `${config.COGNITO_CLIENT_ID}`,
            }
        }).then(successResponse).catch(errorResponse);
    }

    static downloadTrack(accessToken, trackId, successResponse, errorResponse) {
        axios.get(`${config.API_URL}api/track/${trackId}/stream-url`, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${accessToken}`,
                'x-client-id': `${config.COGNITO_CLIENT_ID}`,
            }
        }).then(successResponse).catch(errorResponse);
    }

    static logTrackStartPlaying(accessToken, trackId, playlistSlug, successResponse, errorResponse) {
        axios.post(`${config.API_URL}api/track/${trackId}/start-playing`,
        {
             trackId: trackId,
             slug: playlistSlug
            }, {
                'headers': {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `Bearer ${accessToken}`,
                    'x-client-id': `${config.COGNITO_CLIENT_ID}`,
                },
            }).then(successResponse).catch(errorResponse);
    }

    static checkLogTrackPlaying(accessToken, trackId, guid, successResponse, errorResponse) {
        axios.post(`${config.API_URL}api/track/${trackId}/check-logging/${guid}`,
            {
                trackId: trackId,
                guid: guid
            }, {
                'headers': {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `Bearer ${accessToken}`,
                    'x-client-id': `${config.COGNITO_CLIENT_ID}`,
                },
            }).then(successResponse).catch(errorResponse);
    }

    static logTrackStatus(accessToken, status, trackId, guid, duration, successResponse, errorResponse) {
        axios.post(`${config.API_URL}api/track/${trackId}/log-${status}/${guid}`,
            {
                trackId: trackId,
                guid: guid,
                duration: duration
            }, {
                'headers': {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `Bearer ${accessToken}`,
                    'x-client-id': `${config.COGNITO_CLIENT_ID}`,
                },
            }).then(successResponse).catch(errorResponse);
    }
}

export default medimusic;