import React from 'react';
import cognito from "../library/apiClient/cognito";

function Authenticated(props) {
    const [nextElement, setNextElement] = React.useState(<></>);

    const params = new URL(window.location.href).searchParams;
    const code = params.get('code');
    const accessToken = window.localStorage.getItem('access_token');
    const refreshToken = window.localStorage.getItem('refresh_token');

    React.useEffect(() => {
        if (props.errorMessage) {
            setNextElement(<>
                <p>{props.errorMessage}</p>
                <a href="/">Try again.</a>
            </>);

            return;
        }

        if (props.userInfo) {
            setNextElement(props.children);
            return;
        }

        setNextElement(<></>);
    }, [props.errorMessage, props.userInfo])

    React.useEffect(() => {
        if (accessToken) {
            cognito.getUserInfo(
                accessToken,
                props.authentication.userInfoSuccessResponse,
                (error) => {
                    if (refreshToken) {
                        props.authentication.refreshAccessToken(refreshToken);

                        return;
                    }

                    props.authentication.removeAccessToken();
                    props.authentication.failedResponse(error);
                }
            );

            return;
        } else if (code) {
            props.authentication.getAccessToken(code, () => {
                window.location.href = `/`;
            });
            return;
        }

        // Else, go to the login screen

        window.location.href = cognito.loginURL;
    }, []);

    return (
        <>{nextElement}</>
    );
}

export default Authenticated;