import React from 'react';
import {
    MDBTypography,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBProgress,
    MDBProgressBar,
} from 'mdb-react-ui-kit';
import {Question, Questions} from './PreSurveyModal/Questions';

export default function PreSurveyModal({displaySurveyModal, setDisplaySurveyModal, prePlaylistSurveyCompleted, setPrePlaylistSurveyCompleted}) {

    const toggleOpen = (event) => {
        event.preventDefault();

        setDisplaySurveyModal(!displaySurveyModal);
    }

    const [questionIndex, setQuestionIndex] = React.useState(0);
    let _currentPercentComplete = Math.ceil(((questionIndex+1) / Questions.length) * 100);
    const [currentPercentComplete, setCurrentPercentComplete] = React.useState(_currentPercentComplete);

    React.useEffect(() => {
        let _currentPercentComplete = Math.ceil(((questionIndex+1) / Questions.length) * 100);
        setCurrentPercentComplete(_currentPercentComplete);
    }, [questionIndex]);

    return (
        <MDBModal open={displaySurveyModal} onClose={() => setDisplaySurveyModal(false)} tabIndex='-1' backdrop={false} closeOnEsc={false} staticBackdrop className='survey-modal'>
            <MDBModalDialog size='xl'>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Anxiety Disorder Questionnaire</MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <Question questionIndex={questionIndex}/>

                        <MDBProgress>
                            <MDBProgressBar width={currentPercentComplete} valuemin={0} valuemax={100} />
                        </MDBProgress>
                        <MDBTypography variant='h5' className='survey-pagination'>Question {questionIndex+1} of {Questions.length}</MDBTypography>
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary'
                                onClick={() => {setQuestionIndex(questionIndex-1)}}
                                disabled={questionIndex === 0}
                        >
                            Back
                        </MDBBtn>
                        <MDBBtn onClick={()=>{
                            if (currentPercentComplete === 100) {
                                setDisplaySurveyModal(false);
                                setPrePlaylistSurveyCompleted(true);
                                return;
                            }

                            setQuestionIndex(questionIndex+1);
                        }}>Next</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
}