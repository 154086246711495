import React from 'react';
import {MDBContainer, MDBBtn} from "mdb-react-ui-kit";
import Authenticated from './components/Authenticated';
import UserSettingsModal from './components/UserSettingsModal';
import Playlists from "./components/Playlists";
import Navbar from "./components/Navbar";
import Player from "./components/Player";
import Authentication from "./library/authentication";
import PreSurveyModal from "./components/PreSurveyModal";
import config from './config';

function App() {
    const [errorMessage, setErrorMessage] = React.useState();
    const [userInfoModal, setUserInfoModal] = React.useState(false);
    const [isAuth, setIsAuth] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState();
    const [userInfoAge, setUserInfoAge] = React.useState(window.localStorage.getItem('user_info_age'));
    const [userInfoSex, setUserInfoSex] = React.useState(window.localStorage.getItem('user_info_sex'));
    const [currentPlaylist, setCurrentPlaylist] = React.useState(null);
    const [displaySurveyModal, setDisplaySurveyModal] = React.useState(false);
    const [prePlaylistSurveyCompleted, setPrePlaylistSurveyCompleted] = React.useState(!config.ENABLE_SURVEYS);

    const authentication = new Authentication(
        (response) => {
            setIsAuth(true);
            if (response.data?.sub !== userInfo?.sub) {
                setUserInfo(response.data);
            }
        },
        (error) => {
            setUserInfo(null);
            setIsAuth(false);
            setErrorMessage(error.message);
        }
    );

    React.useEffect(() => {

        if (null === userInfo) {
            window.localStorage.removeItem('user_info');
        } else {
            window.localStorage.setItem('user_info', JSON.stringify(userInfo));
        }

        if (null === userInfoAge) {
            window.localStorage.removeItem('user_info_age');
        } else {
            window.localStorage.setItem('user_info_age', userInfoAge);
        }

        if (null === userInfoSex) {
            window.localStorage.removeItem('user_info_sex');
        } else {
            window.localStorage.setItem('user_info_sex', userInfoSex);
        }

    }, [userInfo, userInfoAge, userInfoSex]);

    React.useEffect(() => {

        if (isAuth && (null === userInfoAge || null === userInfoSex)) {
            setUserInfoModal(true);
        }

    }, [isAuth]);

    return (
      <MDBContainer fluid>
          <Navbar
              isAuth={isAuth}
              userInfo={userInfo}
              displayUserSettings={() => {setUserInfoModal(true)}}
          />
          { isAuth && userInfo &&
              <>
                  { currentPlaylist &&
                      <Player
                          authentication={authentication}
                          currentPlaylist={currentPlaylist}
                          setCurrentPlaylist={setCurrentPlaylist}
                          setDisplaySurveyModal={setDisplaySurveyModal}
                          prePlaylistSurveyCompleted={prePlaylistSurveyCompleted}
                          setPrePlaylistSurveyCompleted={setPrePlaylistSurveyCompleted}
                      />

                  }
                  <Playlists
                      authentication={authentication}
                      isAuth={isAuth}
                      currentPlaylist={currentPlaylist}
                      setCurrentPlaylist={setCurrentPlaylist}
                  />
                  <UserSettingsModal
                      userInfoModal={userInfoModal}
                      setUserInfoModal={setUserInfoModal}
                      userInfo={userInfo}
                      setUserInfo={setUserInfo}
                      userInfoAge={userInfoAge}
                      setUserInfoAge={setUserInfoAge}
                      userInfoSex={userInfoSex}
                      setUserInfoSex={setUserInfoSex}
                  />
                  { !prePlaylistSurveyCompleted && <PreSurveyModal
                      displaySurveyModal={displaySurveyModal}
                      setDisplaySurveyModal={setDisplaySurveyModal}
                      prePlaylistSurveyCompleted={prePlaylistSurveyCompleted}
                      setPrePlaylistSurveyCompleted={setPrePlaylistSurveyCompleted}
                  /> }
              </>
          }
          <Authenticated authentication={authentication}
                         isAuth={isAuth}
                         setIsAuth={setIsAuth}
                         userInfo={userInfo}
                         setUserInfo={setUserInfo}
                         errorMessage={errorMessage}
                         setErrorMessage={setErrorMessage}
          />
          { !isAuth &&
              <section>
                  <div className='px-4 py-5 px-md-5 text-center text-lg-start' style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                      <div className='container'>
                          <div className='row gx-lg-5 align-items-center'>
                              <div className='col-lg-6 mb-5 mb-lg-0'>
                                  <h1 className='my-5 display-3 fw-bold ls-tight'>
                                      The best offer <br />
                                      <span className='text-primary'>for your business</span>
                                  </h1>
                                  <p style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, itaque accusantium odio, soluta,
                                      corrupti aliquam quibusdam tempora at cupiditate quis eum maiores libero veritatis? Dicta facilis sint
                                      aliquid ipsum atque?
                                  </p>
                              </div>

                              <div className='col-lg-6 mb-5 mb-lg-0'>
                                  <div className='card'>
                                      <div className='card-body py-5 px-md-5'>
                                          <MDBBtn onClick={() => window.location.href = '/'}>Single Sign-On Login</MDBBtn>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </section> }
          <footer>
          </footer>
      </MDBContainer>
    );
}

export default App;
