import React from 'react';
import { MDBSelect } from 'mdb-react-ui-kit';


function AgeField(props) {
    const [age, setAge] = React.useState(props.age || `30`);

    const ages = []
    for (let i = 30; i <= 110; i++) {
        ages.push({ text: `${i}`, value: `${i}`})
    }

    return (
        <MDBSelect
            ref={props.elRef}
            label='Select your age:'
            data={ages}
            visibleOptions={3}
            search
            value={age || '30'}
            onValueChange={(e) => {
                setAge(e.value);
                props.onChangeState({
                    age: e.value
                });
            }}
        />
    );
}

export default AgeField;