import medimusic from "../library/apiClient/medimusic";
import cognito from "./apiClient/cognito";

class Authentication {
    constructor(
        userInfoSuccessResponse,
        failedResponse
    ) {
        this.userInfoSuccessResponse = userInfoSuccessResponse;
        this.failedResponse = failedResponse;
    }

    removeAccessToken() {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('token_type');
    }

    setAccessToken(apiResponse) {
        window.localStorage.setItem('access_token', apiResponse.data.access_token);
        window.localStorage.setItem('expires_in', apiResponse.data.expires_in);
        window.localStorage.setItem('id_token', apiResponse.data.id_token);
        window.localStorage.setItem('refresh_token', apiResponse.data.refresh_token);
        window.localStorage.setItem('token_type', apiResponse.data.token_type);
    }
    updateAccessToken(apiResponse) {
        window.localStorage.setItem('access_token', apiResponse.data.access_token);
        window.localStorage.setItem('expires_in', apiResponse.data.expires_in);
        window.localStorage.setItem('id_token', apiResponse.data.id_token);
        window.localStorage.setItem('token_type', apiResponse.data.token_type);
    }

    getAccessToken(code, successCallback = null) {
        medimusic.getAccessToken(
            code,
            (response) => {
                cognito.getUserInfo(
                    response.data.access_token,
                    (_response) => {
                        this.setAccessToken(response);
                        this.userInfoSuccessResponse(_response);

                        if ('function' == typeof successCallback) {
                            successCallback(_response);
                        }
                    },
                    (error) => {
                        this.removeAccessToken();
                        this.failedResponse(error);
                    }
                );
            },
            (error) => {
                this.removeAccessToken();
                this.failedResponse(error);
            }
        );
    }

    refreshAccessToken(refreshToken, successCallback = null) {
        medimusic.refreshAccessToken(
            refreshToken,
            (response) => {
                cognito.getUserInfo(
                    response.data.access_token,
                    (_response) => {
                        this.updateAccessToken(response);
                        this.userInfoSuccessResponse(_response);

                        if ('function' == typeof successCallback) {
                            successCallback(_response);
                        }
                    },
                    (error) => {
                        this.removeAccessToken();
                        this.failedResponse(error);
                    }
                );
            },
            (error) => {
                this.removeAccessToken();
                this.failedResponse(error);
            }
        );
    }

}

export default Authentication;