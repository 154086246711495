import React from 'react';
import { MDBSelect } from 'mdb-react-ui-kit';


function SexField(props) {
    const [mySex, setMySex] = React.useState(props.sex || 'male');

    return (
        <MDBSelect
            ref={props.elRef}
            label='Select your Sex:'
            data={[
                { text: `Male`, value: `male`},
                { text: `Female`, value: `female`}
            ]}
            value={mySex}
            onValueChange={(e) => {
                setMySex(e.value);
                props.onChangeState({
                    sex: e.value
                });
            }}
        />
    );
}

export default SexField;