import React from 'react';
import AgeField from './BiometricForm/AgeField';
import SexField from './BiometricForm/SexField';

function BiometricForm(props) {

    const ageRef = React.useRef();
    const sexRef = React.useRef();
    const [age, setAge] = React.useState(props.userInfo.age);
    const [mySex, setMySex] = React.useState(props.userInfo.sex);
    React.useEffect(() => {
        props.updateState({
            age: age,
            sex: mySex
        });
    }, [age, mySex]);

    return (
        <form data-testid="biometric-form">
            <AgeField elRef={ageRef} age={age} onChangeState={(data) => setAge(data.age)}/>
            <br/>
            <SexField elRef={sexRef} sex={mySex} onChangeState={(data) => setMySex(data.sex)}/>
        </form>
    );
}

export default BiometricForm;