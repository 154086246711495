import React from 'react';
import {MDBRow, MDBCol, MDBIcon, MDBProgress, MDBProgressBar} from "mdb-react-ui-kit";
import medimusic from "../library/apiClient/medimusic";

class Audio extends React.Component {
    constructor(props) {
        super(props);

        this.trackGuids = []

        this.state = {
            duration: null,
            playing: false,
            src: this.props.src,
            currentTrackIndex: this.props.currentTrackIndex,
            currentTrackTime: 0,
            currentTrackTimeEnd: 0,
            currentPercentage: 0,
            logTrack: false,
        }
    };

    convertSecondsToMins(seconds) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const _seconds = Math.floor(seconds - (minutes*60) - (hours*60))

        if (hours > 0) {
            return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${_seconds < 10 ? '0' : ''}${_seconds}`
        } else {
            return `${minutes < 10 ? '0' : ''}${minutes}:${_seconds < 10 ? '0' : ''}${_seconds}`
        }
    }

    handleToggleAudio() {
        if (!this.state.playing) {
            this.audio.play();
        } else {
            this.audio.pause();
        }

        this.setState({playing: !this.state.playing});
    }

    componentDidMount() {

        this.currentTimeInterval = null;

        this.audio.onloadedmetadata = function() {
            this.setState({duration: this.audio.duration});
            this.audio.play();
            this.setState({playing: true});

            if (this.trackGuids[this.props.currentTrack.tg_id] === undefined) {
                medimusic.logTrackStartPlaying(
                    window.localStorage.getItem('access_token'),
                    this.props.currentTrack.tg_id,
                    this.props.currentPlaylist.slug,
                    (response) => {
                        this.trackGuids[this.props.currentTrack.tg_id] = response.data.guid;
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            }
        }.bind(this);

        this.audio.onplay = () => {
            this.currentTimeInterval = setInterval( () => {
                const currentTime = this.audio?.currentTime || 0;
                const duration = this.audio?.duration || 0;
                this.setState({
                    currentTrackTime: currentTime,
                    currentTrackTimeEnd: duration - currentTime,
                    currentPercentage: currentTime / duration,
                });

                if (this.trackGuids[this.props.currentTrack.tg_id] !== undefined
                    && !this.state.logTrack
                    && currentTime > 1
                    && Math.floor(currentTime) % 30 === 0) {
                    this.setState({
                        logTrack: true
                    });
                    medimusic.logTrackStatus(
                        window.localStorage.getItem('access_token'),
                        'progress',
                        this.props.currentTrack.tg_id,
                        this.trackGuids[this.props.currentTrack.tg_id] || '',
                        currentTime
                    );
                }
                if (this.state.logTrack && Math.floor(currentTime) % 31 === 0) {
                    this.setState({
                        logTrack: false
                    });
                }

            }, 100);
        };

        this.audio.onpause = () => {
            clearInterval(this.currentTimeInterval);
        };

        this.audio.onended = () => {

            if (this.trackGuids[this.props.currentTrack.tg_id] !== undefined) {
                medimusic.logTrackStatus(
                    window.localStorage.getItem('access_token'),
                    'end',
                    this.props.currentTrack.tg_id,
                    this.trackGuids[this.props.currentTrack.tg_id] || '',
                    this.audio?.duration || 0
                );
            }

            this.setState({
                currentTrackTime: 0,
                currentTrackTimeEnd: 0,
                currentPercentage: 0,
            })
            this.props.setCurrentTrackIndex(this.state.currentTrackIndex + 1);
            this.setState({currentTrackIndex: this.state.currentTrackIndex + 1});
        };
    }

    componentDidUpdate() {
        if (this.props.src !== this.state.src) {
            this.setState({src: this.props.src});
            this.audio.pause();
            this.audio.load();
            this.audio.play();
        }

        if (this.props.currentTrackIndex !== this.state.currentTrackIndex) {
            this.audio.pause();
        }
    }


    render() {
        return (
            <>
                <MDBCol sm={12} md={5} lg={4} xxl={3} className='player-controls p-0'>
                    <audio ref={(audio) => {
                        this.audio = audio
                    }}>
                        <source src={this.props.src?.aac} type={'audio/mpeg;'} codecs={'aac'}/>
                    </audio>
                    <div>
                        <MDBIcon fas
                                 icon={this.state.playing ? 'pause-circle' : 'play-circle'}
                                 className={'play-pause-control'}
                                 onClick={this.handleToggleAudio.bind(this)}
                        />
                        <MDBIcon far icon="thumbs-up" className="positive-track-feedback"/>
                        <MDBIcon far icon="thumbs-down" className="negative-track-feedback"/>
                    </div>
                </MDBCol>
                <MDBCol sm={12} md={7} lg={8} xxl={9} className='player-progress  p-0'>
                    <MDBRow>
                        { this.state.currentPercentage != 0 && <>
                            <MDBCol sm={2} md={2} lg={1} xxl={1} className='p-1 left-counter'>{this.convertSecondsToMins(this.state.currentTrackTime)}</MDBCol>
                            <MDBCol sm={8} md={8} lg={10} xxl={10} className='p-1'>
                                <MDBProgress>
                                    <MDBProgressBar width={this.state.currentPercentage * 100} valuemin={0} valuemax={100}/>
                                </MDBProgress>
                            </MDBCol>
                            <MDBCol sm={2} md={2} lg={1} xxl={1} className='p-1 right-counter'>{this.convertSecondsToMins(this.state.currentTrackTimeEnd)}</MDBCol>
                        </>}
                    </MDBRow>
                </MDBCol>
            </>
        );
    }
}

export default Audio;