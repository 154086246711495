import React from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import BiometricForm from "./BiometricForm";

export default function UserSettingsModal({
                                              userInfoModal,
                                              setUserInfoModal,
                                              userInfoAge,
                                              setUserInfoAge,
                                              userInfoSex,
                                              setUserInfoSex
}) {

    const [unSavedUserInfo, setUnSavedUserInfo] = React.useState({
        age: userInfoAge,
        sex: userInfoSex
    });

    const toggleOpen = (event) => {
        event.preventDefault();

        setUserInfoModal(!userInfoModal);
    }

    const updateUserInfo = (data) => {
        unSavedUserInfo.age = data.age;
        unSavedUserInfo.sex = data.sex;
        setUnSavedUserInfo(unSavedUserInfo);
    }

    const handleSave = (event) => {
        event.preventDefault();

        setUserInfoAge(unSavedUserInfo.age);
        setUserInfoSex(unSavedUserInfo.sex);

        setUserInfoModal(false);

    }

    return (
        <MDBModal open={userInfoModal} onClose={() => setUserInfoModal(false)} tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>User Settings</MDBModalTitle>
                        <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <BiometricForm userInfo={unSavedUserInfo} updateState={updateUserInfo} />
                    </MDBModalBody>

                    <MDBModalFooter>
                        <MDBBtn color='secondary' onClick={toggleOpen}>
                            Close
                        </MDBBtn>
                        <MDBBtn onClick={handleSave}>Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    );
}