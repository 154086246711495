
import axios from "axios";
import config from '../../config';

const cognitoAuthCodeUrl = `${config.COGNITO_BASE_URL}oauth2/authorize?response_type=code`;
const cognitoUserInfoUrl = `${config.COGNITO_BASE_URL}oauth2/userInfo`;

class cognito {
    static loginURL = `${cognitoAuthCodeUrl}&client_id=${config.COGNITO_CLIENT_ID}&scope=email+openid+phone+profile&redirect_uri=${config.HOST_URL}`;
    static getUserInfo(accessToken, successResponse, errorResponse) {
        axios.get(`${cognitoUserInfoUrl}`, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${accessToken}`,
            }
        }).then(successResponse).catch(errorResponse);

        return successResponse.data;
    }
}

export default cognito;